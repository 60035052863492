import React, { useEffect, useState } from 'react';
import { FloatingPagePortal } from 'ui/components';

const ANIMATION_DURATION = 600;

interface Props {
  title: string;
  info: string;
  onClose: () => void;
}

export default function MoreInfo({ title, info, onClose }: Props) {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (!isClosing) {
      return;
    }

    setTimeout(onClose, ANIMATION_DURATION);
  }, [isClosing, onClose]);

  return (
    <FloatingPagePortal fullSize className={`${isClosing ? 'closing' : ''}`}>
      <div id="FolderMoreInfo_container" className="more-info-container">
        <div className="header-container">
          <div className="title-container">
            <h2 id="Folder_moreInfo___title" className="no-margin">
              {title}
            </h2>
          </div>
          <div className="button-container">
            <div id="FolderMoreInfo_closeButton" className="close-button" onClick={() => setIsClosing(true)} />
          </div>
          <hr className="divider" />
        </div>
        <div className="content-container component-inner">
          <p id="FolderMoreInfo_text" className="more-info-content">
            {info}
          </p>
        </div>
      </div>
    </FloatingPagePortal>
  );
}
