import React, { useEffect, useState } from 'react';
import style from './Pin.module.scss';

interface Props {
  index: number;
  pin: string;
}

export default function DigitMask({ index, pin }: Props) {
  const [maskedValue, setMaskedValue] = useState('−');
  const [removable, setRemovable] = useState(true);

  useEffect(() => {
    if (pin[index] && pin.length === index + 1 && removable) {
      setMaskedValue(pin[index]);
      setRemovable(false);
      setTimeout(() => {
        setMaskedValue('●');
      }, 100);
    }
    if (pin.length === index && !removable) {
      setMaskedValue('−');
      setRemovable(true);
    }
  }, [index, pin, removable]);

  return <span className={pin.length === index ? style.selected : ''}>{maskedValue}</span>;
}
