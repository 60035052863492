import React from 'react';

interface Props {
  className?: string;
  icon: JSX.Element;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

function NavigationArrow({ className, icon, onClick }: Props) {
  return (
    <div className={`navigation-arrow ${className || ''}`} onClick={onClick}>
      {icon}
    </div>
  );
}

export default React.memo(NavigationArrow);
