import { combineReducers } from 'redux';
import { call } from 'redux-saga/effects';
import * as ManagePin from './managePin';
import * as ParentalBadges from './parentalBadges';
import * as Accessibility from './accessibility';

export default combineReducers({
  managePin: ManagePin.default,
  parentalBadges: ParentalBadges.default,
  accessibility: Accessibility.default,
});

export const sagas = [call(ManagePin.saga), call(Accessibility.saga)];
