import React from 'react';
import { ParentalRatingValue } from '@wit/vtv-x-foundation-lib';
import style from './AgeRating.module.scss';
import ParentalRatingIcon from '../Icon/ParentalRatingIcon';
import Icon, { Icons } from '../Icon/Icon';

interface Props {
  onRateClick: (option: ParentalRatingValue) => void;
  ratings?: ParentalRatingValue[];
  activeOption?: ParentalRatingValue;
}

export default function AgeRating({ ratings, activeOption, onRateClick }: Props) {
  const getRatingsOptions = () => {
    const activeIndex = activeOption && ratings?.findIndex((item) => activeOption.ratingValue === item.ratingValue);
    return ratings?.map((item: ParentalRatingValue, index: number) => {
      if (!item.selectable) {
        return null;
      }

      return (
        <div
          className={`${style.ratingItem} ${activeIndex !== undefined && activeIndex > index ? style.completed : ''} ${
            activeIndex !== undefined && activeIndex === index ? style.current : ''
          }`}
          onClick={() => {
            if (onRateClick) {
              onRateClick(item);
            }
          }}
          key={item.ratingValue}
          id={`ParentalControlSettings_rate___${item.ratingValue}`}
        >
          <div className={style.rateCounter}>
            {((activeIndex !== undefined && activeIndex <= index) || !activeIndex) &&
              (activeIndex === index ? (
                <Icon className={style.unlockedIcon} icon={Icons.LOCK_UNLOCKED} />
              ) : (
                <Icon className={style.lockedIcon} icon={Icons.LOCK_LOCKED} />
              ))}
          </div>
          <div
            className={`${style.ratingName} ${
              activeIndex !== undefined && activeIndex >= index && style.ratingNameActive
            }`}
          >
            <ParentalRatingIcon
              parentalRating={item.ratingValue.toString()}
              bemBlock="ParentalControlSettings"
              fallback={<span>{item.label}</span>}
              iconWidth={23}
            />
          </div>
        </div>
      );
    });
  };
  return <div className={style.ratingWrapper}>{getRatingsOptions()}</div>;
}
