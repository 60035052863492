export const getNameInitials = (name: string): string => {
  const result = [];

  const names = name.trim().split(' ');

  if (names.length === 1) {
    result.push(names[0].charAt(0));
  } else if (names.length > 1) {
    result.push(names[0].charAt(0));
    result.push(names[names.length - 1].charAt(0));
  }

  return result.join('');
};

export const colourGroups = [
  // each element (group) contains an array of ASCII codes and colour
  // each element of an ASCII codes list is related to same colour

  {
    ascii: [
      0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 176, 184, 192, 200,
      208, 216, 224, 232, 240, 248,
    ],
    colour: '#FECB00',
  },
  {
    ascii: [
      1, 9, 17, 25, 33, 41, 49, 57, 65, 73, 81, 89, 97, 105, 113, 121, 129, 137, 145, 153, 161, 169, 177, 185, 193, 201,
      209, 217, 225, 233, 241, 249,
    ],
    colour: '#E60000',
  },
  {
    ascii: [
      2, 10, 18, 26, 34, 42, 50, 58, 66, 74, 82, 90, 98, 106, 114, 122, 130, 138, 146, 154, 162, 170, 178, 186, 194,
      202, 210, 218, 226, 234, 242, 250,
    ],
    colour: '#9C2AA0',
  },
  {
    ascii: [
      3, 11, 19, 27, 35, 43, 51, 59, 67, 75, 83, 91, 99, 107, 115, 123, 131, 139, 147, 155, 163, 171, 179, 187, 195,
      203, 211, 219, 227, 235, 243, 251,
    ],
    colour: '#007C92',
  },
  {
    ascii: [
      4, 12, 20, 28, 36, 44, 52, 60, 68, 76, 84, 92, 100, 108, 116, 124, 132, 140, 148, 156, 164, 172, 180, 188, 196,
      204, 212, 220, 228, 236, 244, 252,
    ],
    colour: '#FF0091',
  },
  {
    ascii: [
      5, 13, 21, 29, 37, 45, 53, 61, 69, 77, 85, 93, 101, 109, 117, 125, 133, 141, 149, 157, 165, 173, 181, 189, 197,
      205, 213, 221, 229, 237, 245, 253,
    ],
    colour: '#A8B400',
  },
  {
    ascii: [
      6, 14, 22, 30, 38, 46, 54, 62, 70, 78, 86, 94, 102, 110, 118, 126, 134, 142, 150, 158, 166, 174, 182, 190, 198,
      206, 214, 222, 230, 238, 246, 254,
    ],
    colour: '#EB9800',
  },
  {
    ascii: [
      7, 15, 23, 31, 39, 47, 55, 63, 71, 79, 87, 95, 103, 111, 119, 127, 135, 143, 151, 159, 167, 175, 183, 191, 199,
      207, 215, 223, 231, 239, 247, 255,
    ],
    colour: '#00B0CA',
  },
];

interface CharacterGroupAndColour {
  groupIndex: number;
  groupColour: string;
}

export const getCharacterGroupAndColour = (asciiCode: number): CharacterGroupAndColour => {
  const result: CharacterGroupAndColour = {
    groupIndex: 0,
    groupColour: '',
  };

  for (let i = 0; i < colourGroups.length; i += 1) {
    if (colourGroups[i].ascii.indexOf(asciiCode) > -1) {
      result.groupIndex = i;
      result.groupColour = colourGroups[i].colour;
      break;
    }
  }

  return result;
};

export const getNextGroupIndex = (initialGroupIndex: number): number => {
  let result = initialGroupIndex;

  if (result >= colourGroups.length) {
    result = 0;
  }

  return result;
};
