import React from 'react';
import { MobileWarningDetails } from 'App';
import style from './MobileBadge.module.scss';

interface Props {
  details?: MobileWarningDetails[];
}

export default function MobileBadge({ details }: Props) {
  if (!details) {
    return null;
  }
  return (
    <div className={style.container}>
      {details.map((badge) => {
        const { alt, appUrl, badgeUrl, id } = badge;
        return (
          <a className={style[id]} id={`Button___${id}`} href={appUrl} target="_blank" rel="noopener noreferrer">
            <img id={`Button_image___${id}`} className={style.badge} src={badgeUrl} alt={alt} />
          </a>
        );
      })}
    </div>
  );
}
