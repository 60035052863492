import { Recording, RelativeTime, TimeUtils } from '@wit/vtv-x-foundation-lib';

export default class RecordingUtils {
  /**
   *
   * @param asset
   * @returns Asset relative time
   */
  static getRelativeRecordingTime(asset: Recording): RelativeTime | undefined {
    const { actualStartTime, actualEndTime } = asset;

    if (actualStartTime && actualEndTime) {
      if (TimeUtils.isBeforeNow(actualEndTime)) {
        return RelativeTime.PAST;
      }

      if (TimeUtils.isAfterNow(actualStartTime)) {
        return RelativeTime.FUTURE;
      }

      return RelativeTime.LIVE;
    }

    return undefined;
  }
}
