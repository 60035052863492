import { Program, TimeUtils } from '@wit/vtv-x-foundation-lib';

/**
 *
 * @param prog Program object
 * @returns program's progress percentage.
 */
export function getProgramCurrentProgress(prog: Program, time = TimeUtils.now()): number {
  const now = TimeUtils.parse(time);
  const progressTime = now - TimeUtils.parse(prog.startDate);
  const remainingTime = TimeUtils.parse(prog.endDate) - now;
  const totalPercentage = (progressTime / (progressTime + remainingTime)) * 100;
  const finalProgress = totalPercentage >= 0 ? totalPercentage : 0;
  return finalProgress > 100 ? 100 : finalProgress;
}
