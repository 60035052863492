import { Ducks, UserAuthenticationMode } from '@wit/vtv-x-foundation-lib';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StartUpRoutePath } from 'ui/routes/paths';

type PathAuthModeMap = {
  [key in StartUpRoutePath]?: UserAuthenticationMode;
};

const useAuthModeValidator = (path: StartUpRoutePath) => {
  const [isValid, setIsValid] = useState(false);
  const userAuthenticationModes = useSelector(Ducks.Dms.Selectors.getDmsConfigs)?.userAuthenticationMode;
  const seamlessSelfcareDeeplinkURL = useSelector(Ducks.Dms.Selectors.getDmsConfigs)?.seamlessSelfcareDeeplinkURL;

  useEffect(() => {
    if (!userAuthenticationModes || userAuthenticationModes.length === 0) {
      setIsValid(true);
      return;
    }

    const pathAuthModeMap: PathAuthModeMap = {
      [StartUpRoutePath.CODE]: UserAuthenticationMode.AUTH_LOGIN_CODE,
      [StartUpRoutePath.SIGNIN]: UserAuthenticationMode.AUTH_USER_PASS,
      [StartUpRoutePath.DEEPLINK_SIGNIN]: UserAuthenticationMode.AUTH_DEEPLINK_SIGNIN,
      [StartUpRoutePath.DEEPLINK_RESULT]: UserAuthenticationMode.AUTH_DEEPLINK_SIGNIN,
    };

    const requiredAuthMode = pathAuthModeMap[path];

    if (!requiredAuthMode) {
      setIsValid(true);
    } else if (path === StartUpRoutePath.DEEPLINK_SIGNIN && !seamlessSelfcareDeeplinkURL) {
      setIsValid(false);
    } else {
      setIsValid(userAuthenticationModes.includes(requiredAuthMode));
    }
  }, [path, seamlessSelfcareDeeplinkURL, userAuthenticationModes]);

  return isValid;
};

export default useAuthModeValidator;
